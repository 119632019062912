export default {
  basic: {
    bingoRow: {
      B: {
        color:"yellow"
      },
      I: {
        color:"pink"
      },
      N: {
        color:"lightblue"
      },
      G: {
        color:"grey"
      },
      O: {
        color:"orange"
      },
    }
  }
}