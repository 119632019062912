
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import X2IMage from 'src/assets/image/x2.jpg'
import BingoCard from 'src/components/bingo/bingo-components/BingoCard'
import TopRow from 'src/components/bingo/bingo-components/TopRow'
import PoolballRow from 'src/components/bingo/bingo-components/PoolballRow'
import FooterControls from 'src/components/bingo/bingo-components/FooterControls'
import GoldenBall from 'src/components/bingo/bingo-components/GoldenBall'
import FreeDaub from 'src/components/bingo/bingo-components/FreeDaub'
import {Timer,Timer2,MatchBingos,SpeakNumber ,BingoScoreData} from 'src/components/bingo/BingoFunctions'
import { useAlert } from 'react-alert'
import { emitCustomEvent } from 'react-custom-events'
import {playSound,stopAllMusic,stopMusic} from 'src/components/AudioMedia'
import { useNavigate } from "react-router-dom";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
let matchBingo =  new MatchBingos()
// let randBonus = ['G','G','G']
let randBonus = ['x2','G','+10']

const getRandBonus = () => {
  let rand = Math.floor(Math.random() * (2+1))
  return randBonus[rand]
}
const gameSpeedConst = 3
const gameTimeConst = 120
const bingoMeterConst = 35
const scoreMultiplierTime = 10
const timeAdder = 10
let selectedGoldenBall = null
let subTimerInterval;


function BingoGame({gameStarted,setGameStarted,propBingoNumbers,propBingoItems}) {

  // console.log("game")
  let timer = Timer()
  let bingoTimer = Timer2()
  let score = BingoScoreData()
  const myAlert = useAlert()
  const navigate = useNavigate()
  const gameTime = useRef(gameTimeConst);
  const timeLeft = useRef()
  const [bingoNumbers,setBingoNumbers] = useState([...propBingoNumbers]) // actual valid numbers on the card
  const [calledBingoNumbers,setCalledBingoNumbers] = useState([])
  const [bingos,setBingos] = useState([])
  const [missedBingos,setMissedBingos] = useState([])
  const [matchedBingos,setMatchedBingos] = useState([])
  const [freeDaub,setFreeDaub] = useState(false)
  const [goldenBall,setGoldenBall] = useState(false)
  const [goldenBallNumbers,setGoldenBallNumbers] = useState([])
  const [subTimer,setSubTimer] = useState(0)
  const [numbersCalledCount,setNumbersCalledCount] = useState(0)
  const [bingoMeter,setBingoMeter] = useState({
    filled:0,
    bingoBonus:[],
  })
  const [scoreMultiplier,setScoreMultiplier] = useState(1)
  const [bingoItems,setBingoItems] = useState([...propBingoItems]) // bingo numbers that would be called

  useEffect(()=>{
    if(timer.timeLine <= 0) return
    timeLeft.current = gameTime.current - timer.timeLine
    if(timeLeft.current < 0) return stopGame()
    if(timer.timeLine % gameSpeedConst == 0 || timer.timeLine == 0.05) {
      if(bingoItems[numbersCalledCount]) {
        SpeakNumber(bingoItems[numbersCalledCount])
      }else {
        // console.log(bingoNumbers,"bingoNumbers")
        // console.log(calledBingoNumbers,"calledBingoNumbers")
        // console.log(bingoItems,"bingoItems")
      }
      setNumbersCalledCount((prev)=> prev +1 )
      setCalledBingoNumbers((prev)=>{
        if(bingoItems[prev.length]) {
          bingoTimer.reset(bingoItems[prev.length])
          return [...prev,bingoItems[prev.length]]
        }else{
          return [...prev]
        }
      })
    }
  },[timer.timeLine])

  
const startTimer = () =>{ 
  bingoTimer.start()
  timer.start()
}

useEffect(()=>{
  // alert('freedaub')
    if(gameStarted) {
      if(freeDaub) {
        pauseTimer()
        playSound('success')
      }else{
        if(timer.timeLine > 0) {
          startTimer()
          playSound('missed')
        }
      }
    }
},[freeDaub])

useEffect(()=>{
  if(gameStarted) {
    startGame()
  }
},[gameStarted])

const startGame = async () => {
  stopAllMusic()
  playSound('bingo_ingame_music')
  startTimer()
};


const pauseTimer = () =>{ 
  bingoTimer.pause()
  timer.pause()
}
  


  useEffect(()=>{
    matchBingo.setBingoNumbers(bingoNumbers)
  },[bingoNumbers])

  const handleFreeDaubEvent = (thisBingoDigit) => {
    setFreeDaub(false)
    clearInterval(subTimerInterval)
    if(thisBingoDigit != selectedGoldenBall) {
      selectedGoldenBall = null
      playSound('missed')
      return 
    }
    setBingos((prev)=>{
      return [...prev,thisBingoDigit]
    })
    score.addBingo({
      thisBingoDigit:thisBingoDigit,
      scoreMultiplier:scoreMultiplier
    })
    let newBingoItems = bingoItems.filter(bi => bi != thisBingoDigit)
    setNumbersCalledCount((prev)=> prev +1 )
    setBingoItems(()=>newBingoItems)
    setCalledBingoNumbers((prev) => [...prev,thisBingoDigit])
    emitCustomEvent('cellDaubed',thisBingoDigit)
    selectedGoldenBall = null

  }

  const handleBingoEvent = useCallback((data)=>{
    let thisBingoDigit = data.letter+data.number
    if(freeDaub) return handleFreeDaubEvent(thisBingoDigit)
    emitCustomEvent('bing',{bingoNumber:thisBingoDigit})
    if(calledBingoNumbers.includes(thisBingoDigit)){
      if(!bingos.includes(thisBingoDigit)) {
        if(thisBingoDigit == bingoTimer.relatedNumber) {
          let toAddMeterVal = bingoTimer.timeLine * bingoMeterConst
          let meterValue = toAddMeterVal + bingoMeter.filled
          let bingoBonus = bingoMeter.bingoBonus
          if(bingoBonus.length < 3) {
            if(meterValue >100) {
              meterValue = meterValue - 100
              bingoBonus.push(getRandBonus())
            }
          }else{
            // console.log("MAX VAL REACHED")
            if(meterValue > 100) meterValue = 100
          }
          setBingoMeter((prev) => {
            return { filled:meterValue,bingoBonus:bingoBonus}
          })
        }
        setBingos((prev)=>{
          return [...prev,thisBingoDigit]
        })
        score.addBingo({
          thisBingoDigit:thisBingoDigit,
          scoreMultiplier:scoreMultiplier
        })
        playSound('success')
      } 
    }else{
      setMissedBingos((prev)=>{
        return [...prev,thisBingoDigit]
      })
      score.addMissedBingo(thisBingoDigit)
      playSound('missed')
    }
  }) 

  const initFindBingoEvent = () =>{ 
    let result = matchBingo.matchBingo(bingos,matchedBingos)
    if(result.length) {
      setMatchedBingos((prev)=>{
        return [...prev,...result]
      }) 
      emitCustomEvent('bingoMatched',{bingoData:result})
      result.forEach((bingoData)=>{
        score.addMatchedBingo({
          bingoData:bingoData,
          timeLeft:timeLeft.current
        })
      })
      playSound('bingo')
    }else{
      playSound('missed')
    }
  }

  const selectGoldenBall = async () => {
    clearInterval(subTimerInterval)
    pauseTimer()
    setSubTimer(()=>10)
    let notCalledNumbers = bingoNumbers.filter(num => !calledBingoNumbers.includes(num.letter+num.number))
    notCalledNumbers = notCalledNumbers.map(n => n.letter+n.number) 
    let toDisplayNumbers = getRandNumbers(notCalledNumbers)
    setGoldenBallNumbers(toDisplayNumbers)
    console.log("======================================================")
    console.log(notCalledNumbers,"nonCalledNumbers")
    console.log(calledBingoNumbers,"calledBingoNumbers")
    console.log(toDisplayNumbers,"toDisplayNumbers")
    console.log("======================================================")

    
   
    setGoldenBall(() => true)
    
    return new Promise((resolve,reject)=>{
      let time = 10
      subTimerInterval = setInterval(()=>{
        if(selectedGoldenBall != null) {
          clearInterval(subTimerInterval)
          resolve('selected')
          return
        }
        time--
        setSubTimer((prev => prev - 1))
        if(time <= 0) {
          clearInterval(subTimerInterval)
          setGoldenBall(()=>false)
          startTimer()
          resolve('not')
        }
      },1000)
    })
  }

  const setSelectedGoldenBall = (ball) => {
    selectedGoldenBall = ball
    setGoldenBall(()=>false)
    setGoldenBallNumbers([])
    selectFreeDaub()
  }

  

  
  
  const selectFreeDaub = () => {
    clearInterval(subTimerInterval)
    setFreeDaub(()=>true)
    setSubTimer(()=>10)
    let time = 10
    subTimerInterval = setInterval(()=>{
      time--
      setSubTimer((prev => prev - 1))
      if(time <= 0) {
        clearInterval(subTimerInterval)
        setFreeDaub(false)
        selectedGoldenBall = null
        startTimer()
      }
    },1000)
  }

  const availBingoBonus = async (bonus) => {
    if(!randBonus.includes(bonus)) return 
    if(scoreMultiplier == 2 && bonus === 'x2') return playSound('missed')
      let allBonus = bingoMeter.bingoBonus
      let index = allBonus.findIndex(b => b == bonus)
      if(index > -1) {
        allBonus.splice(index,1)
      }
      setBingoMeter((prev) => {
        return { ...prev,bingoBonus:allBonus}
      })
      playSound('power_avail')
      if(bonus === 'G'){ 
        let isSelected = await selectGoldenBall()
      }else if(bonus === 'x2') {
        setScoreMultiplier((prev) => 2)
        stopMusic('bingo_ingame_music')
        playSound('x2_mode')
        setTimeout(()=>{
          setScoreMultiplier((prev) => 1)
          stopMusic('x2_mode')
          playSound('bingo_ingame_music')
        },scoreMultiplierTime * 1000)
      }else if(bonus === '+10') {
        timeLeft.current = timeLeft.current + timeAdder
      }
      
  }

 const stopGame = () => {
    pauseTimer()
    timer.reset()
    localStorage.setItem('bingoScore', JSON.stringify(score.userScore.current));
    SpeakNumber("Game Over")
    setTimeout(()=>{
      setGameStarted(false)
      navigate('/score')  
    },2000)
    
  }


  
  
   let previousPoolBalls = calledBingoNumbers.slice(-4)
   let lastPoolBall = calledBingoNumbers.slice(-1)[0]
   previousPoolBalls = previousPoolBalls.filter(pb => pb != lastPoolBall)
  return (

    <div className="lg:px-24  rounded-2xl border-4 shadow-2xl bg-opacity-30 bg-white">
      {/* meter : {bingoMeter.filled}
      bouns : {bingoMeter.bingoBonus.map(b => <p>{b}</p>)} */}
      <br/>
      <center>Timeleft: {timeLeft.current}  sm : {scoreMultiplier}</center>
      {/* <GoldenBall/> */}
      {goldenBall && <GoldenBall timer={subTimer} goldenBalls={goldenBallNumbers} setGoldenBall={(ball)=>setSelectedGoldenBall(ball)}/>}
      {!goldenBall && 
      <>
          {scoreMultiplier === 2 && <AnimateKeyframes
              play
              iterationCount="infinite"
              keyframes={["opacity: 0", "opacity: 1"]}
            >
              <center><img src={X2IMage} style={{height:'100px',position:'absolute',left:'46%'}}/></center>
          </AnimateKeyframes> }
          {!freeDaub && <PoolballRow
            previousPoolBalls={previousPoolBalls}
            calledBingoNumbers={calledBingoNumbers}
          />}
          {freeDaub && <FreeDaub timer={subTimer} selectedGoldenBall={selectedGoldenBall}/>}
          <TopRow/>
          <BingoCard
            bingoNumbers={bingoNumbers}
            bingos={bingos}
            freeDaub={freeDaub}
            handleBingoEvent={handleBingoEvent}
          />
          <FooterControls
            initFindBingoEvent={initFindBingoEvent}
            bingoMeter={bingoMeter}
            availBingoBonus={availBingoBonus}
          />
        </>
      }
  </div>
  )
}
function getRandNumbers(array) { 
  let returnArray = [];
  let maxLength = 4
  if(array.length < 4) maxLength = array.length
  while(returnArray.length != maxLength ) {
    let randNo = generateRandom(array.length)
    if(!returnArray.includes(array[randNo])) {
      returnArray.push(array[randNo])
    }
  }
  return returnArray
}

function generateRandom(maxLimit = 100){
  let rand = Math.random() * maxLimit;
  rand = Math.floor(rand);
  return Number(rand)
}
export default BingoGame 