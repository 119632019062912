import PoolBall from "src/components/bingo/elements/PoolBall";

function PoolballRow({ previousPoolBalls, calledBingoNumbers }) {
  return (
    <div className="flex justify-around px-4 sm:px-6 lg:px-8 xl:px-12 py-8 sm:py-16 lg:py-5 mx-auto max-w-3xl">
      {previousPoolBalls.map((number, index) => (
        <PoolBall
          key={index}
          number={number}
          latestCalled={false}
          animateBorder={index === 0}
        />
      ))}
      {calledBingoNumbers.slice(-1).map((number, index) => (
        <PoolBall
          key={index}
          number={number}
          latestCalled={true}
          animateBorder={index === 0}
        />
      ))}
    </div>
  );
}

export default PoolballRow;
