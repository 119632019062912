import { gql } from "@apollo/client";

export const GET_BINGO_GAME = gql`
  query ($id: String!) {
    getBingoData(id: $id) {
      bingoCard {
        B
        I
        N
        G
        O
      }
      bingoItems
    }
  }
`;
