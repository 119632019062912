import PoolBall from 'src/components/bingo/elements/PoolBall'
function GoldenBall({timer,goldenBalls,setGoldenBall}) {
  return (
    <div>
        <center><div className="">Pickup a Golden ball</div></center>
        <br/>
        <center><div className="">{timer}</div></center>
        <div className="grid grid-cols-2 justify-center gap-24 p-12">
          {goldenBalls.map((ball)=>{
            return (  
              <a key={ball} href="#" onClick={(e)=>{e.preventDefault(); setGoldenBall(ball)}}><PoolBall
                size="big"
                number={ball}
              /></a>
            )
          })}
        </div>
      </div>
  );
}
export default GoldenBall;
