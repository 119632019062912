import React, { useState, useEffect } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import BingoGirl from 'src/assets/image/2.png';
import logo from 'src/assets/image/bingologodmxcasino.png';
import FallingCoinsAnimation from 'src/components/coins';
import SideDrawerhome from 'src/components/SideDrawerhome';
import SelectThemePopup from 'src/components/SelectThemePopup';
import { useNavigate } from "react-router-dom";

const Home = ({ initGame }) => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!loading) {
      setShowPopup(true);
    }
  }, [loading]);

  const handlePlayGame = () => {
    const hasSeenInstructions = localStorage.getItem('hasSeenInstructions');

    if (hasSeenInstructions !== 'true') {
      localStorage.setItem('hasSeenInstructions', 'true');
      // window.location.href = '/instructions';
      navigate('/instructions')
    } else {
      // window.location.href = '/game';
      navigate('/game')

    }

    setShowPopup(false);
  };
  const cartoonTextStyle = {
    fontFamily: "'Comic Sans MS', cursive",
    fontSize: '27px',
    color: 'white',
    textShadow: '2px 2px 4px #0000FF',
  };

  return (
    <div className="relative">
      {loading ? (
        <SplashScreen setLoading={setLoading} />
      ) : (
        <div className="relative bg-gray-900 bg-opacity-0">
          <FallingCoinsAnimation className="absolute inset-0" />

          {/* Use the Popup component */}
          <SelectThemePopup showPopup={showPopup} setShowPopup={setShowPopup} />

          <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-3">
            <SideDrawerhome />
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 flex justify-center mx-auto">
              <span className="max-w-lg mb-6 font-sans text-7xl font-bold leading-none tracking-tight text-gray-900 sm:text-7xl md:mx-auto">
                <img src={logo} alt="Logo" />
              </span>
            </div>
            <img
              src={BingoGirl}
              className="w-full mx-auto md:w-auto md:max-w-sm"
              alt="Bingo Girl"
            />
            <div className="flex justify-center">
              <button
                onClick={handlePlayGame}
                className="bg-blue-300 px-10 py-4 text-3xl font-bold text-white rounded-xl -mt-5 border-4 border-white hover:scale-125 transition duration-500 cursor-pointer"
                style={cartoonTextStyle}>
                PLAY GAME
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
