import React, { useState } from 'react';
import 'src/assets/css/SideDrawer.css'; 

const SideDrawerhome = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="popup-container flex justify-end">
      {/* Button to toggle the pop-up */}
      <span onClick={togglePopup} className=" px-4 py-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
        </svg>

      </span>

      {/* Popup Content */}
      {isPopupOpen && (
        <div className="popup w-full h-full flex justify-center">
          <div className="popup-content w-full lg:w-96">
            <span className="close-btn h-16" onClick={togglePopup}>
              &times;
            </span>
           <div className='py-16'><h2>Popup Content</h2>
            <p>This is a pop-up/modal content.</p></div> 
          </div>
        </div>
      )}
    </div>
  );
};

export default SideDrawerhome;
