import { activeThemeMeta } from "src/components/bingo/BingoFunctions";
const themeData = activeThemeMeta()
function BingoRow({letter}) {
let backgroundColor = themeData.bingoRow[letter].color
  return (
    <div
      className="w-16 h-16 border border-gray-400 font-mono font-bold text-2xl cursor-pointer text-center flex items-center justify-center rounded-lg"
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.2s ease-in-out",
        backgroundColor: `${backgroundColor}`,
      }}
    >
      {letter}
    </div>
  );
}

export default BingoRow;
