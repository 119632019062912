import { useEffect } from "react";
import { apolloClient } from "src/index";
import { GET_BINGO_GAME } from "src/queries";

function Test() {
  useEffect(() => {
    (async ()=>{
      try {
        let { data } = await apolloClient.query({
          query: GET_BINGO_GAME,
          variables:{
            id:"qwe"
          },
          fetchPolicy: "no-cache",
        });
      }catch(e) {
        alert(e)
      }
    })()
  }, []);
  return <h1>tesst</h1>;
}

export default Test;
