import { useEffect, useRef, useState } from "react";
import ThemesMeta from 'src/components/bingo/ThemesMeta'
let timeSlice = 500;
const activeTheme = 'basic'

export const Timer = () => {
  const [timeLine, setTimeLine] = useState(0);
  const [active, setActive] = useState(false);
  const [relatedNumber,setRelatedNumber] = useState(null)

  useEffect(() => {
    let interval = setInterval(() => {
      if (active) {
        setTimeLine((prev) => {
          return prev + timeSlice;
        });
      }
    }, timeSlice);
    return () => clearInterval(interval);
  }, [active]);

  return {
    timeLine: timeLine / 1000,
    start: () => setActive(() => true),
    pause: () => setActive(() => false),
    reset: (relatedNumber = null) => { 
      setTimeLine(0)
      if(relatedNumber) {
        setRelatedNumber(relatedNumber)
      }
    },
    status: active,
    relatedNumber:relatedNumber
  };
};

export const Timer2 = () => {
  const timeLine = useRef(0);
  const [active, setActive] = useState(false);
  const [relatedNumber,setRelatedNumber] = useState(null)

  useEffect(() => {
    let interval = setInterval(() => {
      if (active) {
        timeLine.current = timeLine.current + timeSlice
      }
    }, timeSlice);
    return () => clearInterval(interval);
  }, [active]);

  return {
    timeLine: timeLine.current / 1000,
    start: () => setActive(() => true),
    pause: () => setActive(() =>false),
    getCurrentTime: () => timeLine.current,
    reset: (relatedNumber = null) => { 
      timeLine.current = 0
      if(relatedNumber) {
        setRelatedNumber(relatedNumber)
      }
    },
    status: active,
    relatedNumber:relatedNumber
  };
};

export const SpeakNumber = (number) => {
  // return
  const speech = new SpeechSynthesisUtterance(number.toString());
  speech.lang = 'Google UK English Female'; 
  speech.voice = speechSynthesis.getVoices().find(voice => voice.name === 'Google UK English Female');
  window.speechSynthesis.speak(speech);
} 

export const BingoScoreData = () => {
  const score = useRef({
    bingo:[],
    missedBingos:[],
    matchedBingos:[]
  })
  return {
    addBingo:(bingoNumberData) => score.current.bingo.push(bingoNumberData),
    addMissedBingo:(bingoNumber) => score.current.missedBingos.push(bingoNumber),
    addMatchedBingo:(matchedBingo) => score.current.matchedBingos.push(matchedBingo),
    userScore : score
  }
}

export const activeThemeMeta = () => {
  return ThemesMeta[activeTheme]
}



export class MatchBingos {
  possibleBingos = {};
  bingoNumbers = [];

  setBingoNumbers = (numbers) => {
    if (!numbers[0]?.number) return;
    this.bingoNumbers = numbers;
    this.setPossibleBingos();
  };

  matchBingo(bingos,matchedBingos) {
    let allBingos = [
      ...this.possibleBingos.horizontalBingos,
      ...this.possibleBingos.verticalBingos,
      ...this.possibleBingos.diagonalBingos,
    ];
    let foundBingo = []
    allBingos.forEach((bingo) => {
      let find = bingo.filter((b => bingos.includes(b)))
      if(find.length == 5) {
       let alreadyMatched = matchedBingos.filter((matched)=>{
          return JSON.stringify(find) === JSON.stringify(matched)
        })
        if(!alreadyMatched.length) {
          foundBingo.push(bingo)
        }
      }
    })
    return foundBingo
  }

  setPossibleBingos() {
    let horizontalBingos = [];
    let verticalBingos = [];
    let diagonalBingos = [];
    let b5 = [];
    let b6 = [];

    for (let k = 1; k <= 25; k = k + 5) {
      let bingos = [];
      let num = 1 * k;
      for (let i = 1; i <= 5; i++) {
        bingos.push(
          this.bingoNumbers[num - 1].letter + this.bingoNumbers[num - 1].number
        );
        num++;
      }
      horizontalBingos.push(bingos);
    }

    for (let t = 0; t < 5; t++) {
      let bingos = [];
      for (let k = 0; k < 5; k++) {
        let num = 5 * k;
        bingos.push(
          this.bingoNumbers[num + t].letter + this.bingoNumbers[num + t].number
        );
      }
      verticalBingos.push(bingos);
    }

    this.possibleBingos.horizontalBingos = horizontalBingos;
    this.possibleBingos.verticalBingos = verticalBingos;
    let j = 0;
    let z = 0;
    for (let k = 0; k < 5; k++) {
      b5.push(this.bingoNumbers[j].letter + this.bingoNumbers[j].number);
      j = j + 6;
    }
    diagonalBingos.push(b5);
    for (let k = 4; k < 9; k++) {
      b6.push(
        this.bingoNumbers[z + k].letter + this.bingoNumbers[z + k].number
      );
      z = z + 3;
    }
    diagonalBingos.push(b6);

    this.possibleBingos.diagonalBingos = diagonalBingos;
  }
}
