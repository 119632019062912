import React, { useState, useEffect } from 'react';
import 'src/assets/css/SplashScreen.css';
import SplashScreenImage from 'src/assets/image/bingologodmxcasino.png'; 

const SplashScreen = ({ setLoading }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false); 
      setLoading(false); 
    }, 3000); 

    return () => clearTimeout(timeout); 
  }, [setLoading]);

  return (
    <div className={`splash-screen ${visible ? 'visible' : 'hidden'}`}>
      <div className="splash-screen-content">
        <img src={SplashScreenImage} alt="Splash Screen" className="splash-screen-image" />
       
      </div>
    </div>
  );
};

export default SplashScreen;
