import { useEffect, useState } from 'react';
import 'src/assets/css/Balls.css';
import { activeThemeMeta } from "src/components/bingo/BingoFunctions";
const themeData = activeThemeMeta()

function PoolBall({ animateBorder, number, latestCalled ,size = "small" }) {

  let [percent,setPercent] = useState(0)
  useEffect(()=>{
    let interval = setInterval(()=>{
      setPercent(prev => prev + 20)
    },1000)
    return () => clearInterval(interval)
  },[])
 
  let letter = number[0]
  let backgroundColor = themeData.bingoRow[letter].color

 const ballStyle = {
    borderRadius: '50%',
    backgroundColor: backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    color: '#fff',
    fontWeight: 'bold',
    boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
    backgroundImage: '',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    animation: 'drawBorder 2s ease forwards'
  };

  return (
   
  <div className={`${size == 'small' ? 'circle' : 'big-circle'}`} style={ballStyle}>
    {number}
  </div>
  );
}

export default PoolBall;
