import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Coin from 'src/assets/image/coin.jpg'
function Score() {  
  let scoreData = localStorage.getItem('bingoScore')
  if(!scoreData) return <h1>No Data Found</h1>
  scoreData = JSON.parse(scoreData)
  console.log(scoreData)
  const scoreMetaRules = {
    missedHit:10,
    successHit:10,
    bingoMultiplier:500
  }
  const getMatchedBingosData = () => {
    let matchedBingos = scoreData.matchedBingos
    return matchedBingos.map((bingo)=>{
      return Math.ceil(scoreMetaRules.bingoMultiplier / bingo.timeLeft) 
    })
  }
  let finalScore = {
    successHitsScore:function() {
      let socre = scoreData.bingo.reduce((acc,thisScore)=>{
        acc = acc + (thisScore.scoreMultiplier * scoreMetaRules.successHit)
        return acc
      },0)
      return socre
    },
    // successHitsScore: scoreData.bingo.length *  scoreMetaRules.successHit,
    missedHitsScore: - (scoreData.missedBingos.length *  scoreMetaRules.missedHit),
    matchedBingos:getMatchedBingosData(),
    totalScore:function() {
      let matchedBingoScore = getMatchedBingosData().reduce((accumulater,thisScore)=>{
        accumulater = accumulater + thisScore
          return accumulater
      },0)
      return this.successHitsScore() + this.missedHitsScore +matchedBingoScore
    }
  }
  console.log(finalScore,"finalScore")
  return (
    <center>
    <div className="mt-24 w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
    <div className="flex items-center justify-between mb-4">
      <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Final Score</h5>
    </div>
    <div className="flow-root">
      <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
        <li className="py-3 sm:py-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="w-8 h-8 rounded-full" src={Coin} alt="Neil image" />
            </div>
            <div className="flex-1 min-w-0 ms-4">
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                Success Hits
              </p>
            </div>
            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
              {finalScore.successHitsScore()}
            </div>
          </div>
        </li>
        <li className="py-3 sm:py-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="w-8 h-8 rounded-full" src={Coin} alt="Michael image" />
            </div>
            <div className="flex-1 min-w-0 ms-4">
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                Missed Hits
              </p>
            </div>
            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            {finalScore.missedHitsScore}
            </div>
          </div>
        </li>
        <li className="py-3 sm:py-4">
        <div>
        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                Bingos
          </p>
        <ul className=" ml-4 max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
          {finalScore.matchedBingos.map((mb,index)=>{
            return (
              <li className="flex items-center" key={index}>
                <img className="w-8 h-8 rounded-full" src={Coin} alt="Michael image" /> &nbsp; 
                  1 × {mb}
              </li>
            )
          })}
        </ul>
      </div>
        </li>
        <li className="py-3 sm:py-4">
          <div className="flex items-center">
            
            <div className="flex-1 min-w-0 ms-4">
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                Total Score
              </p>
            </div>
            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
              {finalScore.totalScore()}
            </div>
          </div>
        </li>
      </ul>
      <Link to="/game" type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Play Again</Link>
    </div>
  </div>
  </center>
  )
}

export default Score