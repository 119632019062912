import BingoRow from "src/components/bingo/elements/BingoRow";

function TopRow() {
  return (
    <div className="grid grid-cols-5 justify-center gap-2 lg:px-24 px-16 md:px-24 mb-5">
      <BingoRow letter={"B"} />
      <BingoRow letter={"I"} />
      <BingoRow letter={"N"} />
      <BingoRow letter={"G"} />
      <BingoRow letter={"O"} />
    </div>
  );
}

export default TopRow
