import React, { useState } from 'react';
import 'src/assets/css/intructionSlidercard.css';
import { Link, useHistory } from 'react-router-dom';

const Slider = () => {
  const slides = [
    {
      title: 'HOW TO PLAY',
      description: 'Description for Slide 1',
      imageUrl: 'url_for_slide_1_image.jpg',
    },
    {
      title: 'Slide 2',
      description: 'Description for Slide 2',
      imageUrl: 'url_for_slide_2_image.jpg',
    },
    {
      title: 'Slide 3',
      description: 'Description for Slide 3',
      imageUrl: 'url_for_slide_3_image.jpg',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const isLastSlide = currentSlide === slides.length - 1;
  

  return (
    <div className='className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"'>
    <div className="slider flex justify-center mx-auto px-5 ">
      <div className="slider-container px-5 pt-6 pb-5 text-center border border-gray-300 bg-gray-50 rounded lg:w-2/5 w-full p-5 rounded-xl">
        <div className="slider-card" style={{ display: `${currentSlide === 0 ? 'block' : 'none'}` }}>
          
          <h2>{slides[0].title}</h2><img src={slides[0].imageUrl} alt={slides[0].title} className="slider-image" />
          <p>{slides[0].description}</p>
        </div>
        <div className="slider-card" style={{ display: `${currentSlide === 1 ? 'block' : 'none'}` }}>
          <img src={slides[1].imageUrl} alt={slides[1].title} className="slider-image" />
          <h2>{slides[1].title}</h2>
          <p>{slides[1].description}</p>
        </div>
        <div className="slider-card" style={{ display: `${currentSlide === 2 ? 'block' : 'none'}` }}>
          <img src={slides[2].imageUrl} alt={slides[2].title} className="slider-image" />
          <h2>{slides[2].title}</h2>
          <p>{slides[2].description}</p>
        </div>
      </div>
     
    </div> <div className="slider-buttons justify-center mx-auto flex py-10">
        <button onClick={nextSlide} disabled={isLastSlide}>
          Next
        </button>
        {isLastSlide && (
         <Link to="/game"> <button onClick={() => console.log('Continue')} className="continue-button">
            Continue
          </button></Link>
        )}
      </div></div>
  );
};

export default Slider;
