import {useEffect, useRef, useState ,useCallback } from 'react';
import BingoGame from 'src/components/bingo/BingoGame'
import { apolloClient } from "src/index";
import { GET_BINGO_GAME } from "src/queries";
import 'src/assets/css/Board.css';


const Table = () => {
  // console.log("table initted")
  const bingoNumbers = useRef([])
  const bingoItems = useRef([])
  const [gameStarted, setGameStarted] = useState(false);
  const [gameData, setGameData] = useState({
    bingoCard: {
      B: [],
      I: [],
      N: [],
      G: [],
      O: [],
    },
    bingoItems: [],
  })
  const formatBingoNumbers = useCallback(()=>{
    let bingoNumbers = []
    let bingoLetters = ['B','I','N','G','O']
    let count = 0
    let count2 = 0
    let bingoCard = gameData.bingoCard
    for(let i=0;i<29;i++) {
      let thisNum = bingoCard[bingoLetters[count]]
      if(thisNum){ 
        bingoNumbers.push({
          letter:bingoLetters[count],
          number:thisNum[count2]
        })
      }
      count++
      if(count > 5) {
        count = 0
        count2++
      }
    }
    return bingoNumbers
  })
  useEffect(()=>{
    let numbers = formatBingoNumbers()
    bingoNumbers.current = numbers
    bingoItems.current = gameData.bingoItems
  },[gameData])
  const startGame = async () => {
    try {
      let { data } = await apolloClient.query({
        query: GET_BINGO_GAME,
        variables: {
          id: "qwe"
        },
        fetchPolicy: "no-cache",
      });
      if (data.getBingoData) {
        setGameData(()=>{
          return data.getBingoData
        })
          setTimeout(()=>{
            setGameStarted(true)
          },2000)
        }
    } catch (e) {
      alert(e)
    }
  };
  console.log(bingoNumbers.current,"bingoNumbers.current")
  console.log(bingoItems.current,"bingoNumbers.current")

  return (
    <div className="flex flex-col min-h-screen bg-blue items-center justify-center px-2">
      {gameStarted && (
        <BingoGame
        gameStarted={gameStarted}
        setGameStarted={setGameStarted}
        propBingoNumbers={bingoNumbers.current}
        propBingoItems={bingoItems.current}
        />
      )}
      {!gameStarted && (
        <div className="flex justify-center mt-10">
          <button
            style={{
              fontFamily: "'Comic Sans MS', cursive",
              fontSize: '27px',
              color: 'white',
              textShadow: '2px 2px 4px #0000FF',
            }}
            onClick={startGame}
          >
            Start Game
          </button>
        </div>
      )}
    </div>
  );
};

export default Table;
