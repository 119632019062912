import React from 'react';
import sl from 'src/assets/image/sl/sledition.png'

const SelectThemePopup = ({ showPopup, setShowPopup }) => {
  if (!showPopup) {
    return null;
  }

  const handleClose = () => {
    setShowPopup(false);
  };
  const cartoonTextStyle = {
    fontFamily: "'Comic Sans MS', cursive",
    fontSize: '36px',
    color: 'white',
    textShadow: '2px 2px 4px #0000FF',
  };
  const cartoonSubTextStyle = {
    fontFamily: "'Comic Sans MS', cursive",
    fontSize: '20px',
    color: 'blue',
    textShadow: '2px 2px 4px white',
  };
  const cartoonButtonTextStyle = {
    fontFamily: "'Comic Sans MS', cursive",
    fontSize: '20px',
    color: 'black',
    textShadow: '2px 2px 4px white',
    borderRadius:'10px',
    bordered :'10px'
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-2xl text-center border-4 border-white "  style={{ background: 'skyblue',
        backgroundImage: 'linear-gradient(45deg, #FFDE00, #FFAA00)',
        borderRadius: '5px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
        filter: 'saturate(1) contrast(1.2)',
        // height: '100vh',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        color: '#000',}}>
        <h2 className="text-3xl font-bold mb-4 font-serif" style={cartoonTextStyle} >Welcome To DMX Casino</h2>
        <p className="text-lg mb-6 font-serif" style={cartoonSubTextStyle}>Pick a Layout</p>
        <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 grid-cols-2 lg:max-w-screen-lg ">
        <div>
          <div className="relative pb-56 mb-4 rounded shadow lg:pb-64 lg:w-44 w-full bg-white cursor-pointer ">
            <img
              className="absolute object-fit w-full h-full rounded border-4 border-white"
              src={sl}
              alt="Person"
            />
          </div>
          <div className="flex flex-col sm:text-center">
            <p className="text-lg font-bold  font-serif  bg-white rounded-xl">SL Edition</p>
            
          </div>
        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow lg:pb-64 bg-white cursor-pointer ">
            <img
              className="absolute object-fit w-full h-full rounded border-4 border-white"
              src={sl}
              alt="Person"
            />
          </div>
          <div className="flex flex-col sm:text-center">
            <p className="text-lg font-bold  font-serif  bg-white rounded-xl">SL Edition</p>
            
          </div>
        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow lg:pb-64 bg-white cursor-pointer ">
            <img
              className="absolute object-fit w-full h-full rounded border-4 border-white"
              src={sl}
              alt="Person"
            />
          </div>
          <div className="flex flex-col sm:text-center">
            <p className="text-lg font-bold  font-serif  bg-white rounded-xl">SL Edition</p>
            
          </div>
        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow lg:pb-64 bg-white cursor-pointer ">
            <img
              className="absolute object-fit w-full h-full rounded border-4 border-white"
              src={sl}
              alt="Person"
            />
          </div>
          <div className="flex flex-col sm:text-center">
            <p className="text-lg font-bold  font-serif  bg-white rounded-xl">SL Edition</p>
            
          </div>
        </div>
      </div>
        <button
          onClick={handleClose} style={cartoonButtonTextStyle}
          className="bg-blue-100 px-6 py-3 mt-3 text-xl font-semibold text-white rounded-md hover:bg-yellow-400 border-4 focus:outline-none hover:scale-125 transition duration-500 cursor-pointer"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SelectThemePopup;
