// import React, { useEffect } from 'react';

// const FallingCoinsAnimation = () => {
//   useEffect(() => {
//     const startAnimation = () => {
//       var exists = document.getElementById('gimmick');
//       if (exists) {
//         exists.parentNode.removeChild(exists);
//         return false;
//       }

//       var canvas = document.createElement('canvas'),
//         ctx = canvas.getContext('2d'),
//         focused = false;

//       const screenWidth = window.innerWidth;
//       const screenHeight = window.innerHeight;

//       canvas.width = screenWidth;
//       canvas.height = screenHeight;
//       canvas.id = 'gimmick';
//       canvas.style.position = 'absolute';
//       canvas.style.top = '0';
//       canvas.style.left = '0';
//       canvas.style.zIndex = '1';
//       document.body.appendChild(canvas);

//       var coin = new Image();
//       coin.src = 'http://i.imgur.com/5ZW2MT3.png';
     
//       coin.onload = function () {
//         focused = true;
//         drawloop();
//       };
//       var coins = [];

//       function drawloop() {
//         if (focused) {
//           setTimeout(() => {
//             requestAnimationFrame(drawloop);
//           }, 50);

//           ctx.clearRect(0, 0, canvas.width, canvas.height);

//           if (Math.random() < 0.2) {
//             coins.push({
//               x: Math.random() * canvas.width | 0,
//               y: -50,
//               dy: 3,
//               s: 0.5 + Math.random(),
//               state: Math.random() * 10 | 0
//             });
//           }

//           var i = coins.length;
//           while (i--) {
//             var x = coins[i].x;
//             var y = coins[i].y;
//             var s = coins[i].s;
//             var state = coins[i].state;
//             coins[i].state = (state > 9) ? 0 : state + 0.1;
//             coins[i].dy += 0.3;
//             coins[i].y += coins[i].dy;

//             ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s);

//             if (y > canvas.height) {
//               coins.splice(i, 1);
//             }
//           }
//         }
//       }
//     };

//     startAnimation();

//     return () => {
//       document.getElementById('gimmick').remove();
//     };
//   }, []);

//   return null; 
// };

// export default FallingCoinsAnimation;
// FallingCoinsAnimation.js


// wokring fine

// export default FallingCoinsAnimation;
import React, { useEffect } from 'react';
import '../assets/css/FallingCoinsAnimation.css'; 
import snowflakeImage from 'src/assets/image/coin.png'; 

const FallingCoinsAnimation = () => {
  useEffect(() => {
    
  }, []);

  const generateRandomValue = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1));
  };

  const snowflakes = Array.from({ length: 50 }, (_, i) => {
    const randomX = Math.random() * 100; // Adjust this to your desired range
    const randomOffset = generateRandomValue(-100, 100);
    const randomXEnd = randomX + randomOffset;
    const randomXEndYoyo = randomX + randomOffset / 2;
    const randomYoyoTime = generateRandomValue(30000, 80000) / 100000;
    const randomYoyoY = randomYoyoTime * window.innerHeight;
    const randomScale = Math.random();
    const fallDuration = generateRandomValue(10, 30);
    const fallDelay = Math.random() * -30;

    const snowClass = `snow snow-${i + 1}`;

    const snowStyle = {
      left: `${randomX}vw`,
      transform: `translateY(30px) scale(${randomScale})`,
      animation: `fall ${fallDuration}s ${fallDelay}s linear infinite`,
      backgroundImage: `url(${snowflakeImage})`, // Set the background image
      backgroundSize: 'contain', // Adjust as needed
    };

    return <div key={i} className={snowClass} style={snowStyle}></div>;
  });

  return (
    <div className="falling-coins-container">
      {snowflakes}
    </div>
  );
};

export default FallingCoinsAnimation;


