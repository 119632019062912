import BingoRoutes from 'src/router'
import {playSound,stopMusic} from 'src/components/AudioMedia'
import { useEffect } from 'react';
import { useNavigate ,useLocation } from "react-router-dom";


export const App = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=>{
    try{
      stopMusic('bingo_ingame_music')
      playSound('main_bingo_music')
    }catch(e) {
      console.log(e)
    }

  },[location])
// console.log(navigate,"navvvvv")
  useEffect(()=>{
    window.addEventListener("click", () => {
      playSound('main_bingo_music')
    }, { once: true });
  },[])
  return (
    <>
    <BingoRoutes/>
    </>
    
  );
};

export default App;
