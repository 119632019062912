import PoolBall from "src/components/bingo/elements/PoolBall";
function FreeDaub({timer,selectedGoldenBall}) {
  return (
    <div className="p-4">
      <center>
        Free daub
        <PoolBall size="big" number={selectedGoldenBall} />
        <div className="mt-4">{timer}</div>
      </center>
    </div>
  );
}

export default FreeDaub;
