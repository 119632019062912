import { useEffect, useState } from 'react';
import 'src/assets/css/animations.css'
import { useCustomEventListener } from 'react-custom-events'

function BingoCell({cellData,bingos,freeDaub}) {
  const [shake, setShake] = useState(false);
  const [isBingoMatched,setBingoMatched] = useState(false)
  const [daubed,setDaubed] = useState(false)

  
  const animate = () => {
    setShake(true);
    setTimeout(() => setShake(false), 1000/2);
  }
  useCustomEventListener('bing', (data) => {
    if(cellData.letter+cellData.number == data.bingoNumber) {
      animate()
    }
  })
  useCustomEventListener('bingoMatched', (data) => {
    let bingoData = data.bingoData
    bingoData.forEach(bingoRow => {
      let findCell = bingoRow.find((c)=>{
        return c == cellData.letter+cellData.number
      })
      if(findCell) {
        setBingoMatched(true)
        animate()
      }  
    });
  })
  useCustomEventListener('cellDaubed', (data) => {
    if(cellData.letter+cellData.number == data) {
      setDaubed(true)
    }
  })
  const containsBingo = bingos.find(b => b == cellData.letter+cellData.number)

  useEffect(()=>{
    if(!isBingoMatched && !containsBingo && freeDaub){ 
      let interval = setInterval(()=>{
        animate()
      },1000)
      return () => clearInterval(interval)
    }
  },[freeDaub])
  
  
  return (
    <div
      className={` ${shake ? 'shake' : ''} w-16 h-16 border border-gray-400 font-mono font-bold text-2xl cursor-pointer text-center flex items-center justify-center rounded-lg`}
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.2s ease-in-out",
        color: !isBingoMatched ? containsBingo ? "white" : freeDaub ? 'white' : 'black' : 'white',
        backgroundColor: !isBingoMatched ? containsBingo ? "red" : freeDaub ? 'skyblue' :"white" : 'orange',
      }}
    >
      {!daubed ? cellData.number : "*"}
    </div>
  );
}


export default BingoCell