const Meter = ({filled}) => {
  return (
    <div className='flex justify-center mx-auto'>
      <div className=''>
        <div
          style={{
            width: '200px',
            height: '30px',
           
            position: 'relative',
        
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', 
            borderRadius: '20px', 
          }}
          className='rounded-xl mb-2 border-4 border-yellow-400 bg-yellow-50 '
        >
          <div
            style={{
              width: `${filled}%`,
              height: '100%',
              
              borderRadius: '20px', 
            }}
            className='rounded-xl bg-yellow-300'
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Meter;
