import {Routes , Route } from "react-router-dom";

import Home from 'src/views/Home'
import Game from 'src/views/Game'
import Intructions from 'src/views/instructions'
import Test from 'src/views/Test'
import Score from 'src/views/Score'
function BingoRouter() {
  return(
    <Routes>
      <Route exact path ="/" element= {<Home/>}/> 
      <Route exact path ="/game" element= {<Game/>}/> 
      <Route exact path ="/instructions" element= {<Intructions/>}/> 
      <Route exact path ="/test" element= {<Test/>}/> 
      <Route exact path ="/score" element= {<Score/>}/> 



    </Routes>
  )
}


export default BingoRouter