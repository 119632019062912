import MissAudio from 'src/assets/sounds/miss.mp3'
import SuccessAudio from 'src/assets/sounds/successhit.mp3'
import Bingo from 'src/assets/sounds/bingo.mp3'
import MainBingoMusic from 'src/assets/sounds/bingo_start.mp3'
import BingoInGame from 'src/assets/sounds/bingo_in_game2.mp3'
import PowerAvail from 'src/assets/sounds/power_avail.wav'
import X2Mode from 'src/assets/sounds/x2mode.mp3'




export const missAudio = new Audio(MissAudio)
export const successAudio = new Audio(SuccessAudio)
export const BingoAudio = new Audio(Bingo)
export const MainBingoMusicAudio = new Audio(MainBingoMusic)
export const BingoInGameAudio = new Audio(BingoInGame)
export const PowerAvailAudio = new Audio(PowerAvail)
export const X2ModeAudio = new Audio(X2Mode)





export const playSound = (soundType) => {
  if(soundType == 'missed') {
    playAudio(missAudio)

  }else if(soundType == 'success'){ 
    playAudio(successAudio)
  }
  else if(soundType == 'bingo'){ 
    playAudio(BingoAudio)
  }
  else if(soundType == 'main_bingo_music'){ 
    playAudio(MainBingoMusicAudio,false)
  }
  else if(soundType == 'bingo_ingame_music'){ 
    playAudio(BingoInGameAudio)
    BingoInGameAudio.volume = 0.3;
  }
  else if(soundType == 'power_avail'){ 
    playAudio(PowerAvailAudio)
  }
  else if(soundType == 'x2_mode'){ 
    playAudio(X2ModeAudio,true)
  }
}
 const playAudio = (audio,repeat = true) => {
  if (audio.paused) {
    if(repeat) audio.currentTime = 0
    audio.play().catch((e)=>{
      console.log(e)
    }).then(()=>{
      // console.log('music')
    })
    }else{
      if(repeat) audio.currentTime = 0 
    }
}
export const stopMusic = (audioName) => {
  if(audioName == 'bingo_ingame_music') BingoInGameAudio.pause()
  if(audioName == 'x2_mode') X2ModeAudio.pause()

}

export const stopAllMusic = () => {
  missAudio.pause()
  successAudio.pause()
  BingoAudio.pause()
  MainBingoMusicAudio.pause()
}

