import Meter from "src/components/bingo/elements/Meter";

function FooterControls({ initFindBingoEvent, bingoMeter, availBingoBonus }) {
  let bingoBonus = [...bingoMeter.bingoBonus]
  let bonusLength = bingoBonus.length

  for(let i=0;i < 3 - bonusLength ;i++) {
    bingoBonus.push("X")
  }
  return (
    <>
      <div className="flex justify-center mt-4">
      </div>
      <div className="">
        <Meter className="mt-2" filled={bingoMeter.filled} />
        <div className="flex justify-center">
          {bingoBonus.map((bonus,index)=>{
            return (
              <div  key={index} style={{backgroundColor: bonus == 'X' ? "lightyellow" : "#FFD700",width:'44px',height:'40px',color:bonus == 'X' ? "black" : "white",cursor:'pointer'}} onClick={() =>availBingoBonus(bonus)} className="ml-2  rounded-full text-center relative"><div className="absolute inset-0 top-2">{bonus}</div></div>
            )  
          })}
        </div>
      </div>
     
      <div className="flex justify-center  py-2">
        <button
          onClick={() => initFindBingoEvent()}
          style={{
            padding: "10px 20px",
            backgroundColor: "#FFD700",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            outline: "none",
            transition: "background-color 0.3s ease-in-out",
            display: "block",
          }}
          className=" uppercase"
        >
          Bingo
        </button>
      </div>
    </>
  );
}

export default FooterControls;
