import BingoCell from 'src/components/bingo/elements/BingoCell'

function BingoCard({bingoNumbers,bingos,freeDaub,handleBingoEvent}) {
  return (
    <div className="flex justify-center mx-auto  ">
      <div className="grid grid-cols-5 justify-center gap-2">
        {bingoNumbers.map((bingoNumbers, index) => {
          return (
            <div key={index} onClick={() => handleBingoEvent(bingoNumbers)}>
              <BingoCell
                bingos={bingos}
                cellData={bingoNumbers}
                freeDaub={freeDaub}
                key={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BingoCard
